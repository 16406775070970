import noUiSlider from 'nouislider';
const slidebarContainerSelector = '[data-range-slider]';
const slidebarSelector = '[data-input-range-slider]';
const inputMinSelector = '[data-min-input]';
const inputMaxSelector = '[data-max-input]';

export default class FormRange {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.slidebarEl = this.root.querySelector(slidebarSelector);
        this.minEl = this.root.querySelector(inputMinSelector);
        this.maxEl = this.root.querySelector(inputMaxSelector);

        return true;
    }

    init() {
        this.setSlidebars();
    }

    setSlidebars() {
        if (!this.slidebarEl) return;

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        let dataInitMin = parseInt(this.settings.init_min);
        let dataInitMax = parseInt(this.settings.init_max);

        noUiSlider.create(this.slidebarEl, {
            start: [dataInitMin, dataInitMax],
            connect: true,
            step: 1,
            behaviour: 'tap-drag',
            range: {
                min: this.settings.min,
                max: this.settings.max,
            },
        });

        let minEl = this.minEl;
        let maxEl = this.maxEl;
        this.slidebarEl.noUiSlider.on('update', function (values, handle) {
            if (minEl) {
                minEl.value = parseInt(values[0]);
            }
            if (maxEl) {
                maxEl.value = parseInt(values[1]);
            }
        });
        this.slidebarEl.noUiSlider.on('end', function (values, handle) {
            if (minEl) {
                var event = new Event('change')
                minEl.dispatchEvent(event)
            }
            if (maxEl) {
                var event = new Event('change')
                maxEl.dispatchEvent(event)
            }
        });

        minEl.addEventListener('change', event => {
            this.updateSlidebar(this.slidebarEl.noUiSlider, minEl, maxEl);
        });
        // minEl.addEventListener('input', (event) => {
        //     this.updateSlidebar(this.slidebarEl.noUiSlider, minEl, maxEl)
        // });
        maxEl.addEventListener('change', event => {
            this.updateSlidebar(this.slidebarEl.noUiSlider, minEl, maxEl);
        });
        // maxEl.addEventListener('input', (event) => {
        //     this.updateSlidebar(this.slidebarEl.noUiSlider, minEl, maxEl)
        // });
    }

    updateSlidebar(slidebar, minEl, maxEl) {
        let range = [];
        if (minEl) {
            range.push(parseInt(minEl.value));
        }
        if (maxEl) {
            range.push(parseInt(maxEl.value));
        }

        if (range) {
            slidebar.updateOptions({
                start: range,
            });
        }
    }

    disable() {
        this.root.classList.add('-disabled');
        // this.sliderInstance.disabled(true);
        this.slidebarEl.noUiSlider.updateOptions({
            start: [
                parseInt(this.settings.init_min),
                parseInt(this.settings.init_max),
            ],
        });
    }

    enable() {
        this.root.classList.remove('-disabled');
        this.slidebarEl.noUiSlider.updateOptions({
            start: [
                parseInt(this.settings.init_min),
                parseInt(this.settings.init_max),
            ],
        });
    }
}

// import rangeSlider from 'range-slider-input';

// export default class FormRange {
//     constructor(el) {
//         if (!this.set(el)) return;
//         this.init();
//     }

//     set(el) {
//         if (!el) return false;

//         this.root = el;
//         this.id = this.root.getAttribute('id');
//         this.slider = this.root.querySelector('[data-form-range-slider]');
//         this.minInput = this.root.querySelector('[data-form-range-min-input]');
//         this.maxInput = this.root.querySelector('[data-form-range-max-input]');

//         this.sliderInstance = null;

//         this.settings = JSON.parse(this.root.getAttribute('data-settings'));

//         return true;
//     }

//     init() {
//         this.setRangeSlider();
//     }

//     setRangeSlider() {
//         if (!this.slider) return;

//         this.sliderInstance = rangeSlider(this.slider, {
//             value: [this.settings.init_min, this.settings.init_max],
//             min: this.settings.min,
//             max: this.settings.max,
//             step: 1,
//             onInput: (value, userInteraction) => {
//                 const min = value[0];
//                 const max = value[1];

//                 this.minInput.value = min;
//                 this.maxInput.value = max;
//             },
//         });
//     }

//     disable() {
//         this.root.classList.add('-disabled');
//         this.sliderInstance.disabled(true);
//         this.sliderInstance.value([]);
//         this.minInput.value = null;
//         this.maxInput.value = null;
//     }

//     enable() {
//         this.root.classList.remove('-disabled');
//         this.sliderInstance.disabled(false);
//         this.sliderInstance.value([
//             this.settings.init_min,
//             this.settings.init_max,
//         ]);
//     }
// }
